// Button.jsx
import React, { useCallback, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { useRipple } from '@/hooks/useRipple.jsx';
import Spinner from '@/components/v2/Spinner.jsx';

const Button = ({ children, className, disabled = false, loading = false, type = 'button', onClick, ...properties }) => {
    const buttonRef = useRef(null);
    const { create: createRipple, render: renderRipple } = useRipple();
    const isDisabled = disabled || loading;

    const handleClick = useCallback(
        (event) => {
            if (isDisabled) return;
            createRipple(event);
            if (onClick) onClick(event);
        },
        [onClick, createRipple, isDisabled],
    );

    return (
        <motion.button
            ref={buttonRef}
            className={twMerge('relative font-semibold rounded-lg overflow-hidden', 'h-14 px-8 text-base', 'bg-sage-green', 'hover:brightness-90', isDisabled && 'opacity-50 cursor-not-allowed', className)}
            onClick={handleClick}
            disabled={isDisabled}
            type={type}
            {...properties}
        >
            <div className='relative z-10'>
                <AnimatePresence initial={false}>
                    {loading ? (
                        <motion.div key='spinner' animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }} className='absolute inset-0 flex items-center justify-center'>
                            <Spinner />
                        </motion.div>
                    ) : (
                        <motion.div className='flex items-center justify-center gap-2' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} key='button-children'>
                            {children}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            {renderRipple()}
        </motion.button>
    );
};

export default Button;
